<template>
  <div
    class="flex relative z-10 whitespace-nowrap overflow-x-auto text-sm sm:text-base px-4 sm:px-0 select-none"
    aria-label="Tabs"
    ref="pageTabs"
  >
    <component
      :is="tab.href ? TheNuxtLink : 'div'"
      v-for="tab in props.tabs"
      :to="tab.href"
      @click="!noEmit && $emit('update:modelValue', tab.value as T)"
      class="overflow-hidden relative block px-4 py-2 min-w-[6rem] font-medium text-center transition-all flex-shrink-0 cursor-pointer"
      :class="{
        'opacity-50 hover:opacity-90': !isSame(modelValue as T, tab.value),
        'opacity-100': isSame(modelValue as T, tab.value),
        'disabled !opacity-30': tab.disabled,
      }"
      :aria-current="isSame(modelValue as T, tab.value) ? 'page' : undefined"
      :ref="setItemRef"
    >
      <span
        class="font-bold opacity-0 transition-all"
        :class="isSame(modelValue as T, tab.value) && 'opacity-100'"
      >
        {{ tab.name }}
      </span>
      <div
        class="absolute inset-0 px-4 py-2 transition-all tracking-wide"
        :class="isSame(modelValue as T, tab.value) && 'opacity-0'"
      >
        {{ tab.name }}
      </div>
    </component>
    <div class="selector"></div>
  </div>
</template>

<script setup lang="ts" generic="T extends string">
import type { ComponentPublicInstance } from "vue";
import TheNuxtLink from "./the/TheNuxtLink.vue";
import type { PageTab } from "~/types/navigation";

type Props<T> = {
  modelValue: T;
  tabs: PageTab<T>[];
  noEmit?: boolean;
};

type Events = {
  (e: "update:modelValue", v: T): void;
};

const props = defineProps<Props<T>>();
const emit = defineEmits<Events>();

const pageTabs = ref<HTMLDivElement>();

const tabHighlightLeft = ref(0);
const tabHighlightWidth = ref(0);

const itemRefs = ref<HTMLElement[]>([]);
const setItemRef = (el: Element | ComponentPublicInstance | null) => {
  if ((el as ComponentPublicInstance)?.$el)
    itemRefs.value.push((el as ComponentPublicInstance).$el as HTMLElement);
  else itemRefs.value.push(el as HTMLElement);
};

onBeforeUpdate(() => {
  itemRefs.value = [];
});

watch([() => props.modelValue, () => props.tabs], () => setHighlightPosition());

const setHighlightPosition = () => {
  const index = props.tabs.findIndex((tab) =>
    isSame(tab.value, props.modelValue),
  );

  if (index === -1) {
    return;
  }

  const tabRef = itemRefs.value[index];

  tabHighlightLeft.value = Math.max(tabRef?.offsetLeft ?? 1, 0);
  tabHighlightWidth.value = Math.max((tabRef?.clientWidth ?? 0) - 2, 0);
};

function isSame(pathA: string, pathB: string) {
  return pathA.split("?")[0] === pathB.split("?")[0];
}

onMounted(() => {
  window.addEventListener("resize", setHighlightPosition);
  setHighlightPosition();
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", setHighlightPosition);
});

defineExpose({
  updateHighlightPosition: () => setHighlightPosition(),
});
</script>

<style scoped lang="postcss">
.selector {
  width: calc(v-bind(tabHighlightWidth) * 1px);
  left: calc(v-bind(tabHighlightLeft) * 1px);
  height: 2px;

  @apply absolute bg-nami-comi-blue bottom-0 transition-all;
}
</style>
